<template>
  <section
    ref="page"
    class="page-container">



    <el-form
      ref="form"
      :model="formData"
    >
      <el-row
        :gutter="40"
        justify="space-around">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="17"
          :xl="17"
          class="page-content-and-up"
        >
          <div class="flex-justify flex">
            <div
              class="header-title-back flex"
              @click="handleBack"
            >
              <i class="el-icon-arrow-left"></i>
              <span>
                Back
              </span>
            </div>
            <div class="flex">
              <div v-if="isNewPolicy">
                <v-button
                  class="button-theme"
                  @click="hanlderDownload">
                  <font-awesome-icon
                    icon="print"
                    class="margin-right"></font-awesome-icon>
                  <span>Renewal Request</span>
                </v-button>
              </div>
              <div
                v-if="formData.can_delete"
                class="mg-l-10">
                <v-button

                  class="button-delete"
                  @click="handlerDelete">
                  <font-awesome-icon
                    icon="trash"
                    class="margin-right"

                  ></font-awesome-icon>
                  <span>Delete</span></v-button>
              </div>
            </div>
          </div>
          <template    v-if="!isCancel&&!incomplete.includes('send_quote')&&!isRenewOnHold">
            <claim-black-list-tip
              v-if="clientBlackData.blacklist||clientBlackData.claimed||clientBlackData.vip"
              class="mg-b-20"
              :client-black-data="clientBlackData"></claim-black-list-tip>
            <select-payment
              ref="set-payment"
              :form="formData"
              :isPayment="isPayment"
              :isLock="isLock"
              :formDataLock="formDataLock"
              @getFormData="getSubFormData"
              @handlerApprove="handlerApprove"
            ></select-payment>
          </template>
          <div
            v-if="incomplete"
            class="premium-info--ul">
            <ul>
              <li v-if="incomplete.includes('send_quote')">
                <span class="flex flex-center">
                  <span class="icon-error">!</span>
                  <span class="span-options orange">Incomplete</span>
                  <span>Checked & SendRenew Quote to client</span>
                </span>
                <span>
                  <v-button
                    class="button-theme"
                    @click="handleSend">Send</v-button>
                </span>
              </li>
            </ul>
          </div>
          <div v-if="isEndorsement">
            <div class="flex-justify">
              <v-title title="Endorsement"></v-title>
              <div >
                <v-button
                  class="button-theme"
                  @click="handlerEndorsementEdit"
                >
                  Edit
                </v-button>
              </div>
            </div>
            <endrosment-premium :form="formData"></endrosment-premium>
          </div>

          <!--          cancel-->
          <div v-if="isCancel">
            <div class="flex-justify">
              <v-title title="Cancellation"></v-title>
              <div >
                <v-button
                  class="button-theme"
                  @click="handleCancelEdit"
                >
                  Edit
                </v-button>
              </div>
            </div>
            <cancel-premium
              :isInfo="true"
              :form="formData"></cancel-premium>
          </div>
          <supplementary-info
            v-if="renew_supplementary.length>0"
            :form="formData"></supplementary-info>
          <div class="flex-justify">
            <v-title :title="isRenewOnHold?'Renew On Hold':'Policy Summary'"></v-title>
            <div v-if="!isEndorsement&&!isCancel">
              <v-button
                class="button-theme"
                @click="handlerEdit"
              >
                Edit
              </v-button>
            </div>
          </div>
          <template v-if="isRenewOnHold">
            <div class="table">
              <div class="th" >
                <span>Remarks (On Hold Reason)</span>
              </div>
              <div
                class="tr">
                <!--      <span v-html="remark"></span>-->
                <p v-html="renew_remark"></p>
              </div>
            </div>
            <div
              ref="document"
            >
              <document-info
                :is-renew-on-hold="isRenewOnHold"
                :form="formData"></document-info>
            </div>
          </template>
          <template v-else>
            <moto-info
              v-if="productId==10"
              ref="info"
              :formData="formData"></moto-info>
            <constractors-info
              v-if="productId==11"
              :formData="formData"></constractors-info>
            <travel-info
              v-if="productId==12"
              :formData="formData"></travel-info>
            <home-info
              v-if="productId==13"
              :formData="formData"></home-info>
            <employees-info
              v-if="productId==14"
              :formData="formData">
            </employees-info>
            <business-info
              v-if="productId==15"
              :formData="formData"></business-info>
            <domestic-info
              v-if="productId==16"
              :formData="formData"></domestic-info>
            <construction-info
              v-if="productId==17"
              :formData="formData">
            </construction-info>
            <other-info
              v-if="productId==18"
              :formData="formData"></other-info>
          </template>
        </el-col>
        <el-col
          class="padding-none-sm-and-down  page-content-auto"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5">
          <div class="page-content-fixed">
            <discount-detail
              v-if="!isEndorsement&&!isCancel"
              :isNext="true"
              :type="2"
              :is-save="incomplete.includes('send_quote')"
              :is-send-quote="incomplete.includes('send_quote')"
              disabled
              :is-endorsement="isEndorsement"
              :carTypeName="formData.class_id"
              :is-now-renew-on-hold="isRenewOnHold"
              :insurer="formData.company_id"
              :form="formData.premium"
              @next="handlerValidate"
              @cancel="handlerCancel"
            ></discount-detail>
            <endorsement-dialog
              v-if="isEndorsement"
              :is-next="true"
              :form="formData"
              :isLock="isLock"
              @handlerConfirm="handlerConfirm"
              @handlerNext="handlerValidate"></endorsement-dialog>
            <cancel-dialog
              v-if="isCancel"
              :is-next="true"
              :isLock="isLock"
              :form="formData"
              @handlerConfirm="handlerConfirm"
              @handlerNext="handlerCancelConfirm"></cancel-dialog>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <send-dialog
      ref="sendDialog"
      v-model="showSendDialog"
      :order-no="orderNo"
      :form="formData"
      :upload-type="3"
      @refresh="handleRefersh"
    ></send-dialog>
  </section>
</template>

<script>
import discountDetail from '@/views/components/discountDetail/discountDetail'
import selectPayment from '@/views/components/selectPayment/selectPayment'
import endorsementDialog from '@/views/components/dialog/endorsementDialog'
import endrosmentPremium from '@/views/components/info/endrosmentPremium'
import supplementaryInfo from '@/views/components/supplementary-info'
import cancelPremium from '@/views/components/info/cancelPremium'
import cancelDialog from '@/views/components/dialog/cancelDialog'
import motoInfo from '@/views/quotation/motor/motoInfo'
import constractorsInfo from '@/views/quotation/contractors/constractorsInfo'
import travelInfo from '@/views/quotation/travel/travelInfo'
import homeInfo from '@/views/quotation/home/homeInfo'
import employeesInfo from '@/views/quotation/employees/employeesInfo'
import BusinessInfo from '@/views/quotation/business/businessInfo'
import domesticInfo from '@/views/quotation/domestic/domesticInfo'
import ConstructionInfo from '@/views/quotation/construction/constructionInfo'
import {mapActions, mapState} from 'vuex'
import OtherInfo from '@/views/quotation/other/otherInfo'
import documentInfo from '@/views/components/info/documentInfo'
import {
  approvePayment,
  deleteOrder,
  getOrderDetail,
  orderDownQuotation,
  setOrderConfirm,
  setOrderPayment,
} from '@api/order'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import sendDialog from '@/views/components/dialog/sendDialog'
import ClaimBlackListTip from '@/views/components/claimBlackListTip'
export default {
  name: 'info',
  components: {
    // PolicyDocument,
    discountDetail,
    selectPayment,
    endrosmentPremium,
    endorsementDialog,
    motoInfo,
    constractorsInfo,
    cancelPremium,
    cancelDialog,
    travelInfo,
    homeInfo,
    employeesInfo,
    BusinessInfo,
    domesticInfo,
    ConstructionInfo,
    OtherInfo,
    supplementaryInfo,
    sendDialog,
    documentInfo,
    ClaimBlackListTip
  },
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isBack:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      approve:false,
      showSendDialog:false,
      isPayment:false,
      formDataLock:0,
      formData:{
        payment:{}
      },
      subForm: {
        'set-payment':false,
        // 'policy-document':false,
      },
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    renew_remark(){
      let {remark,renew_remark} = this.formData||{}
      return renew_remark?renew_remark.replace(/\n/g, '<br/>'):'-'
    },
    isRenewOnHold(){
      let {renew_on_hold} = this.formData||{}
      return renew_on_hold==1
    },
    renew_supplementary(){
      let {renew_supplementary} = this.formData
      return renew_supplementary||[]
    },
    incomplete(){
      let {incomplete} = this.formData
      return incomplete||[]
    },
    orderNo(){
      return this.$route.query.order_no
    },
    isNewPolicy()
    {
      let {policy_type} = this.formData||{}
      return [201].includes(policy_type)
    },
    isEndorsement()
    {
      return this.formData.policy_type ==203
    },
    isCancel(){
      return this.formData.policy_type ==204
    },
    productId(){
      return this.formData.product_id
    },
    isLock(){
      let {premium={}} = this.formData||{}
      return (premium.is_refund_order||premium.amount_received<=0)&&(this.isEndorsement||this.isCancel)
    },
    isPolicyList(){
      let {isPolicyList} = this.$route.query||{}
      return isPolicyList == 'true'
    }
  },

  created() {
    this.network().getOrderDetail()
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    ...mapActions('common',['setIsBackPath','checkBlacklistStatus']),
    handleCheckBlack(){
      let {policy_holder_id_type,policy_holder_id_no,holder_company_id_no,client_type} = this.formData
      let params = {
        id_type :client_type==2?3:policy_holder_id_type,
        id_no:client_type==2?holder_company_id_no:policy_holder_id_no
      }
      this.checkBlacklistStatus(params)
    },
    handleRefersh(){
      let path ='/renewal/pending'
      let {is_continue_renew} = this.$route.query
      if(is_continue_renew == 1){
        this.network().getOrderDetail()
        return
      }
      // let path ='/renewal/renew'
      // let query = {}
      // if(this.isRenewOnHold){
      //   path='/renewal/renew'
      //   query={
      //     name:'renew_on_hold'
      //   }
      // }
      this.$router.push({
        path:path,
        // query
      })
    },
    handleSend(){
      this.showSendDialog = true
    },
    handleBack(){
      this.setIsBackPath(true)
      this.$router.back()
    },
    hanlderDownload(){
      // this.$refs.page.scrollIntoView({behavior: 'smooth', block: 'end',})
      if(this.isRenewOnHold){
        // this.$refs.document.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start',
        //   inline: 'nearest'
        // })
      }else 
        this.$refs.info.scrollToSection()
    },
    handlerDelete(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.network().deleteOrder()
      })
    },
    handlerEndorsementEdit(){
      let order_no = this.$route.query.order_no
      let params = {
        ...this.quoteInformationForm,
        is_edit: 1,
        order_no
      }
      this.setQuoteInformationForm(params)
      this.$router.push('/quotation/endorsement')
    },
    handleCancelEdit(){
      this.$router.push('/quotation/cancel')
    },
    handlerEdit(){
      let path = {
        10:'/quotation/create/motor',
        11:'/quotation/create/contractors',
        12:'/quotation/create/travel',
        13:'/quotation/create/home',
        14:'/quotation/create/employee',
        15:'/quotation/create/business',
        16:'/quotation/create/domestic',
        17:'/quotation/create/construction',
        18:'/quotation/create/other',
      }
      let {is_continue_renew,order_no} = this.$route.query
      this.$router.push({
        path:path[this.productId],
        query:{
          order_no,
          isDraft:true,
        }
      })
    },
    handlerCancelConfirm(){
      let order_no = this.$route.query.order_no
      this.$router.push({
        path:'/quotation/create/policy',
        query:{
          order_no
        }
      })
    },
    handlerEndorsement(){
      let order_no = this.$route.query.order_no
      let {order_status,policy_type} = this.formData
      if(order_status>10&&policy_type==203)
        this.$router.push({
          path:'/quotation/premium/info',
          query:{
            order_no:order_no
          }
        })
    },
    handlerCancel(){
      let {order_status,policy_type} = this.formData
      // if(policy_type!=200) return

    },
    handlerApprove(){
      this.approve=true
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment} = this.formData
      let {handle_name } = payment
      let name =handle_name
      this.$ConfirmBox({
        title: '',
        message: name+',are you confirmed that the payment is complete?'
      }).then(() => {
        this.isPayment?this.network().approvePayment(): this.handlerValidate()
      })
    },
    handlerConfirm(){
      if(this.isEndorsement){
        Object.keys(this.subForm).some(key => {
          this.subForm[key] = false
          this.$refs[key].submitForm(key)
        })
        if (Object.values(this.subForm).some(item => item === false)) {
          return
        }
      }
      this.network().setOrderConfirm()
    },
    handlerValidate() {
      // 獲取表單組件數據
      let order_no = this.$route.query.order_no

      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {payment,policy_id} = this.formData
      let { type,last_name,first_name,handle_name } = payment

      if(type==2&&!this.approve)
      {
        this.$ConfirmBox({
          title: '',
          message: 'Approve Cheque later'
        }).then(() => {
          if(this.isPayment)
          {
            this.$router.push({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.network().setOrderPayment()
        })
      }
      else if(type==1){
        let name = type==1?`${last_name} ${first_name}`:handle_name
        this.$ConfirmBox({
          title: '',
          message: 'SMS Payment will send to  '+name+',are you confirmed?'
        }).then(() => {
          this.network().setOrderPayment()
        })
      } else{
        this.network().setOrderPayment()

      }
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    openFileDocument(data){
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    network() {
      return {
        getOrderDetail: async () => {
          let order_no = this.$route.query.order_no
          let { data } = await getOrderDetail({order_no:order_no})
          let {payment}  =data.data
          if(payment)
            this.isPayment =true
          this.formData = data.data
          this.formDataLock = 1
          this.handlerEndorsement()
          //编辑的时候
          let params = {
            is_edit:1,
            order_no:this.orderNo,
            ...this.formData
          }
          // let {is_continue_renew} = this.$route.query
          // if(is_continue_renew==1){
          //   params.is_continue_renew = 1
          // }
          this.setQuoteInformationForm(params)
          this.setProductID(this.formData.product_id)
          this.handleCheckBlack()
        },
        setOrderPayment:async (params) => {
          let order_no = this.$route.query.order_no
          let {policy_id,policy_no,covernote_no,policy_document_id,covernote_document_id} = this.formData

          params = {...this.formData.payment,...{order_no:order_no},policy_no,covernote_no,covernote_document_id,policy_document_id}

          let { data } = await setOrderPayment(params)
          let {type} = this.formData.payment
          this.isPayment = true

          if(this.approve)
          {
            this.network().approvePayment()
          }else{
            if(type==2)
              this.$router.push({
                path:'/quotation/premium/info',
                query:{
                  order_no
                }
              })
            else
              this.$router.push({
                path:'/quotation/premium/info',
                query:{
                  order_no
                }
              })
          }
        },
        approvePayment:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id} = this.formData
          this.approve = false
          let { data } = await approvePayment({order_no:order_no})
          this.$router.push({
            path:'/policy/info',
            query:{
              policy_id
            }
          })
        },
        setOrderConfirm:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id,policy_no,covernote_no,policy_document_id,covernote_document_id} = this.formData
          let params = {order_no:order_no,policy_no,covernote_no,covernote_document_id,policy_document_id}
          let { data } = await setOrderConfirm(params)
          this.$router.push({
            path:'/quotation/premium/info',
            query:{
              order_no
            }
          })
        },
        orderDownQuotation:async (params) => {
          let order_no = this.$route.query.order_no
          // const { data } = await orderDownQuotation({order_no:order_no})
          // this.network().getOrderDetail()
          this.$refs.page.scrollIntoView({behavior: 'smooth', block: 'end',})

          // this.quotationData = data
          // this.openFileDocument(data)
        },
        deleteOrder: async () => {
          let order_no = this.$route.query.order_no
          let params ={
            order_no:order_no
          }
          let { data } = await deleteOrder(params)
          let {policy_id} =data||{}
          if(policy_id>0)
          {
            this.$router.replace({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.isPolicyList?this.$router.replace('/policy/list'):this.$router.replace('/policy/draft')
          // this.setQuoteInformationForm(null)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .page-container{
    .header-title{
      padding: 10px 0;
    }
    ::v-deep .table{
      .th{
        background: #CBD5E0;
        box-shadow: 0px 1px 0px #CBD5E0;
        font-weight: bold;
        font-size: 14px;
      }

    }
    .margin-right{
      margin-right: 5px;
    }
    .mg-l-10{
      margin-left: 10px;
    }
  }
  .premium-info--ul{
    margin-bottom: 20px;
    ul{
      li{
        padding: 10px 20px;
        background: #FFFFFF;
        border: 1px solid #CBD5E0;
        margin: -1px 0px;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        justify-content: space-between;
        color: #2D3748;
      }
    }

    .span-options{
      padding: 2px 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      background: #fff;
      border-radius: 4px;
      margin-left: 15px;
      margin-right: 15px;
      &.orange{
        color: #ED8936;
        border: 1px solid #ED8936;
      }
    }
  }
</style>
